@import 'colors';

@font-face {
	font-family: Rubik;
	src: url(../../../assets/fonts/Rubik/Rubik-Regular.ttf) format('truetype');
}

@font-face {
	font-family: Rubik-Bold;
	src: url(../../../assets/fonts/Rubik/Rubik-Medium.ttf) format('truetype');
	font-weight: bold;
}

@font-face {
	font-family: Rubik-Italic;
	src: url(../../../assets/fonts/Rubik/Rubik-Italic.ttf) format('truetype');
	font-style: italic;
}

html {
	position: relative;
	min-height: 100%;
	font-size: 14px;
	line-height: 1.429rem;
}

body {
	margin: 0;
	color: $eqa-color-base-006;
	text-align: left;
	min-height: 100vh;
	max-height: 100vh;
	position: relative;
	font-family: Rubik, sans-serif;
	background-color: $eqa-color-base-001;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

.clearfix::after {
	display: block;
	clear: both;
	content: '';
}

*:disabled,
*.disabled {
	pointer-events: none;
	opacity: 0.8;
}

hr {
	box-sizing: content-box;
	height: 0;
	overflow: visible;
	width: 100%;
}

button,
input,
[contenteditable],
a:focus,
button:focus,
button:active,
input:focus,
textarea:focus {
	outline: none;
	border: none;
}

button:active {
	outline: none;
	border: none;
}

a:active {
	outline: none;
}

pre,
code,
kbd,
samp {
	font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
	font-size: 1em;
}

pre {
	margin-top: 0;
	margin-bottom: 1rem;
	overflow: auto;
}

p {
	margin-top: 0;
	margin-bottom: 1rem;
}

address {
	margin-bottom: 1rem;
	font-style: normal;
	line-height: inherit;
}

abbr[title],
abbr[data-original-title] {
	text-decoration: none;
	-webkit-text-decoration: underline dotted;
	text-decoration: underline dotted;
	cursor: help;
	border-bottom: 0;
	-webkit-text-decoration-skip-ink: none;
	text-decoration-skip-ink: none;
}

blockquote {
	margin: 0 0 1rem;
}

b,
strong {
	font-weight: bolder;
}

small {
	font-size: 80%;
}

sub,
sup {
	position: relative;
	font-size: 75%;
	line-height: 0;
	vertical-align: baseline;
}

sub {
	bottom: -0.25em;
}

sup {
	top: -0.5em;
}

figure {
	margin: 0 0 1rem;
}

img {
	vertical-align: middle;
	border-style: none;
}

table {
	border-collapse: collapse;
}

caption {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	color: #6c757d;
	text-align: left;
	caption-side: bottom;
}

th {
	text-align: inherit;
	font-weight: normal;
}

label {
	display: inline-block;
	margin-bottom: 0.5rem;
}

button {
	border-radius: 0;
}

input,
button,
select,
optgroup,
textarea {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

button,
input {
	overflow: visible;
}

button,
select {
	text-transform: none;
}

select {
	word-wrap: normal;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
	-webkit-appearance: button;
}

button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
	cursor: pointer;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
	padding: 0;
	border-style: none;
}

input[type='radio'],
input[type='checkbox'] {
	box-sizing: border-box;
	padding: 0;
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
	-webkit-appearance: listbox;
}

textarea {
	overflow: auto;
	resize: vertical;
}

fieldset {
	min-width: 0;
	padding: 0;
	margin: 0;
	border: 0;
}

legend {
	display: block;
	width: 100%;
	max-width: 100%;
	padding: 0;
	margin-bottom: 0.5rem;
	font-size: 1.5rem;
	line-height: inherit;
	color: inherit;
	white-space: normal;
}

progress {
	vertical-align: baseline;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
	height: auto;
}

[type='search'] {
	outline-offset: -2px;
	-webkit-appearance: none;
}

[type='search']::-webkit-search-decoration {
	-webkit-appearance: none;
}

::-webkit-file-upload-button {
	font: inherit;
	-webkit-appearance: button;
}

output {
	display: inline-block;
}

summary {
	display: list-item;
	cursor: pointer;
}

template {
	display: none;
}

[hidden] {
	display: none !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	margin-bottom: 0;
}

hr {
	border: 0;
	border-top: 0.071rem solid $eqa-color-base-004;
}

mark,
.mark {
	padding: 0.2em;
	background-color: #fcf8e3;
}

.blockquote {
	margin-bottom: 1rem;
	font-size: 1.25rem;
}

.blockquote-footer {
	display: block;
	font-size: 80%;
	color: #6c757d;
}

.blockquote-footer::before {
	content: '\2014\00A0';
}

code {
	font-size: 87.5%;
	color: #e83e8c;
	word-break: break-word;
}

a > code {
	color: inherit;
}

kbd {
	padding: 0.2rem 0.4rem;
	font-size: 87.5%;
	color: #fff;
	background-color: #212529;
	border-radius: 0.2rem;
}

kbd kbd {
	padding: 0;
	font-size: 100%;
	font-weight: 700;
}

pre {
	display: block;
	font-size: 87.5%;
	color: #212529;
}

pre code {
	font-size: inherit;
	color: inherit;
	word-break: normal;
}

.pre-scrollable {
	max-height: 340px;
	overflow-y: scroll;
}

ol,
ul,
dl {
	margin: 0;
	padding: 0;
	list-style: none;
}

dt {
	font-weight: 700;
}

dd {
	margin-bottom: 0.5rem;
	margin-left: 0;
}

.cursor-pointer {
	cursor: pointer;
}

.table-fixed {
	table-layout: fixed;
}

.h-100 {
	height: 100% !important;
	max-height: 100% !important;
}

.text-wrap {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.demo-element {
	cursor: not-allowed !important;
}
