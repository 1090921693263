@import 'colors';
@import 'variables';

.border {
	border: $eqa-border-base-003;
}

.border-none {
	border: none;
}

.border-bottom {
	border-bottom: $eqa-border-base-003;
}

.border-0 {
	border: 0 !important;
}

.border-top-0 {
	border-top: 0 !important;
}
