// base gray pallette
$eqa-color-base-001: #ffffff;
$eqa-color-base-002: #fdfdfd;
$eqa-color-base-003: #f2f2f2;
$eqa-color-base-004: #dee2e6;
$eqa-color-base-005: #a4aab0;
$eqa-color-base-006: #36373b;

// base green pallet
$eqa-color-green-001: #bdefdf;
$eqa-color-green-002: #83cebb;
$eqa-color-green-003: #2dbf8f;
$eqa-color-green-004: #05a081;
$eqa-color-green-005: #048b70;

// base blue pallet
$eqa-color-blue-001: #c0d5ec;
$eqa-color-blue-002: #3879be;

// base red pallet
$eqa-color-red-001: #ffc8c2;
$eqa-color-red-002: #ff6252;

//base yellow pallet
$eqa-color-yellow-001: #ffecc2;
$eqa-color-yellow-002: #ffc23c;
