.order-first {
	-ms-flex-order: -1;
	order: -1;
}

.order-last {
	-ms-flex-order: 13;
	order: 13;
}

.order-0 {
	-ms-flex-order: 0;
	order: 0;
}

.order-1 {
	-ms-flex-order: 1;
	order: 1;
}

.order-2 {
	-ms-flex-order: 2;
	order: 2;
}

.order-3 {
	-ms-flex-order: 3;
	order: 3;
}

.order-4 {
	-ms-flex-order: 4;
	order: 4;
}

.order-5 {
	-ms-flex-order: 5;
	order: 5;
}

.order-6 {
	-ms-flex-order: 6;
	order: 6;
}

.order-7 {
	-ms-flex-order: 7;
	order: 7;
}

.order-8 {
	-ms-flex-order: 8;
	order: 8;
}

.order-9 {
	-ms-flex-order: 9;
	order: 9;
}

.order-10 {
	-ms-flex-order: 10;
	order: 10;
}

.order-11 {
	-ms-flex-order: 11;
	order: 11;
}

.order-12 {
	-ms-flex-order: 12;
	order: 12;
}

.offset-1 {
	margin-left: 8.333333%;
}

.offset-2 {
	margin-left: 16.666667%;
}

.offset-3 {
	margin-left: 25%;
}

.offset-4 {
	margin-left: 33.333333%;
}

.offset-5 {
	margin-left: 41.666667%;
}

.offset-6 {
	margin-left: 50%;
}

.offset-7 {
	margin-left: 58.333333%;
}

.offset-8 {
	margin-left: 66.666667%;
}

.offset-9 {
	margin-left: 75%;
}

.offset-10 {
	margin-left: 83.333333%;
}

.offset-11 {
	margin-left: 91.666667%;
}

.d-none {
	display: none;
}

.d-visible-none {
	visibility: hidden;
}

.d-inline {
	display: inline !important;
}

.d-inline-block {
	display: inline-block !important;
}

.d-block {
	display: block !important;
}

.d-table {
	display: table !important;
}

.d-table-row {
	display: table-row !important;
}

.d-table-cell {
	display: table-cell !important;
}

.d-flex {
	display: -ms-flexbox !important;
	display: flex !important;
}

.p-fixed {
	position: fixed;
	top: 0;
	left: 0;
}

.d-inline-flex {
	display: -ms-inline-flexbox !important;
	display: inline-flex !important;
}

.embed-responsive {
	position: relative;
	display: block;
	width: 100%;
	padding: 0;
	overflow: hidden;
}

.embed-responsive::before {
	display: block;
	content: '';
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;
}

.embed-responsive-21by9::before {
	padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
	padding-top: 56.25%;
}

.embed-responsive-4by3::before {
	padding-top: 75%;
}

.embed-responsive-1by1::before {
	padding-top: 100%;
}

.flex-row {
	-ms-flex-direction: row !important;
	flex-direction: row !important;
}

.flex-column {
	-ms-flex-direction: column !important;
	flex-direction: column !important;
}

.flex-column > div {
	flex: 1;
}

.flex-row-reverse {
	-ms-flex-direction: row-reverse !important;
	flex-direction: row-reverse !important;
}

.flex-column-reverse {
	-ms-flex-direction: column-reverse !important;
	flex-direction: column-reverse !important;
}

.flex-wrap {
	-ms-flex-wrap: wrap !important;
	flex-wrap: wrap !important;
}

.flex-nowrap {
	-ms-flex-wrap: nowrap !important;
	flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
	-ms-flex-wrap: wrap-reverse !important;
	flex-wrap: wrap-reverse !important;
}

.flex-fill {
	-ms-flex: 1 1 auto !important;
	flex: 1 1 auto !important;
}

.flex-grow-0 {
	-ms-flex-positive: 0 !important;
	flex-grow: 0 !important;
}

.flex-grow-1 {
	flex: 1;
}

.flex-shrink-0 {
	-ms-flex-negative: 0 !important;
	flex-shrink: 0 !important;
}

.flex-shrink-1 {
	-ms-flex-negative: 1 !important;
	flex-shrink: 1 !important;
}

.flex-grow-shrink-auto {
	flex: 1 1 auto;
}

.justify-self-start {
	justify-self: start;
}

.justify-self-end {
	justify-self: end;
}

.justify-content-start {
	-ms-flex-pack: start !important;
	justify-content: flex-start !important;
}

.justify-content-end {
	-ms-flex-pack: end !important;
	justify-content: flex-end !important;
}

.justify-content-center {
	-ms-flex-pack: center !important;
	justify-content: center !important;
}

.justify-content-between {
	-ms-flex-pack: justify !important;
	justify-content: space-between !important;
}

.justify-content-around {
	-ms-flex-pack: distribute !important;
	justify-content: space-around !important;
}

.align-items-start {
	-ms-flex-align: start !important;
	align-items: flex-start !important;
}

.align-items-end {
	-ms-flex-align: end !important;
	align-items: flex-end !important;
}

.align-items-center {
	-ms-flex-align: center !important;
	align-items: center !important;
}

.align-items-baseline {
	-ms-flex-align: baseline !important;
	align-items: baseline !important;
}

.align-items-stretch {
	-ms-flex-align: stretch !important;
	align-items: stretch !important;
}

.align-content-start {
	-ms-flex-line-pack: start !important;
	align-content: flex-start !important;
}

.align-content-end {
	-ms-flex-line-pack: end !important;
	align-content: flex-end !important;
}

.align-content-center {
	-ms-flex-line-pack: center !important;
	align-content: center !important;
}

.align-content-between {
	-ms-flex-line-pack: justify !important;
	align-content: space-between !important;
}

.align-content-around {
	-ms-flex-line-pack: distribute !important;
	align-content: space-around !important;
}

.align-content-stretch {
	-ms-flex-line-pack: stretch !important;
	align-content: stretch !important;
}

.align-self-auto {
	-ms-flex-item-align: auto !important;
	align-self: auto !important;
}

.align-self-start {
	-ms-flex-item-align: start !important;
	align-self: flex-start !important;
	border-radius: 50%;
}

.align-self-end {
	-ms-flex-item-align: end !important;
	align-self: flex-end !important;
}

.align-self-center {
	-ms-flex-item-align: center !important;
	align-self: center !important;
}

.align-self-baseline {
	-ms-flex-item-align: baseline !important;
	align-self: baseline !important;
}

.align-self-stretch {
	-ms-flex-item-align: stretch !important;
	align-self: stretch !important;
}

.align-baseline {
	vertical-align: baseline !important;
}

.align-top {
	vertical-align: top !important;
}

.align-middle {
	vertical-align: middle !important;
}

.align-bottom {
	vertical-align: bottom !important;
}

.align-text-bottom {
	vertical-align: text-bottom !important;
}

.align-text-top {
	vertical-align: text-top !important;
}

.sticky-top {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 1;
}
