.ios-logo {
	height: 25px;
	width: 25px;
	display: block;
	background: url(../../../assets/icons/common/apple.svg) no-repeat;
}

.web-logo {
	height: 25px;
	width: 25px;
	display: block;
	background: url(../../../assets/icons/common/web.svg) no-repeat;
}

.desktop-logo {
	height: 25px;
	width: 25px;
	display: block;
	background: url(../../../assets/icons/common/dektop.svg) no-repeat;
}

.android-logo {
	height: 25px;
	width: 23px;
	display: block;
	background: url(../../../assets/icons/common/android.svg) no-repeat;
}

.jira-logo {
	height: 24px;
	width: 25px;
	display: block;
	background: url(../../../assets/icons/integrations/jira-logo.svg) no-repeat;
	background-size: cover;
}

.slack-logo {
	height: 24px;
	width: 25px;
	display: block;
	background: url(../../../assets/icons/integrations/slack-logo.svg) no-repeat;
	background-size: cover;
}

.telegram-logo {
	height: 24px;
	width: 25px;
	display: block;
	background: url(../../../assets/icons/integrations/telegram-logo.svg) no-repeat;
	background-size: cover;
}

.trello-logo {
	height: 24px;
	width: 25px;
	display: block;
	background: url(../../../assets/icons/integrations/trello-logo.svg) no-repeat;
	background-size: cover;
}

.github-logo {
	height: 24px;
	width: 25px;
	display: block;
	background: url(../../../assets/icons/integrations/github-logo.svg) no-repeat;
	background-size: cover;
}

.gitlab-logo {
	height: 24px;
	width: 25px;
	display: block;
	background: url(../../../assets/icons/integrations/gitlab-logo.svg) no-repeat;
	background-size: cover;
}

.bitbucket-logo {
	height: 24px;
	width: 25px;
	display: block;
	background: url(../../../assets/icons/integrations/bitbucket-logo.svg) no-repeat;
	background-size: cover;
}

.link-out {
	height: 16px;
	width: 16px;
	display: block;
	background: url(../../../assets/icons/links/link-out.svg) no-repeat;
	background-size: cover;
}

.icon {
	display: block;
	background-size: contain;
	padding: 0;
}
