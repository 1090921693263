.content {
	min-height: 100vh;
}

.overlay {
	display: flex;
	top: 0;
	bottom: 0;
	width: 100%;
	height: calc(100vh - 48px);

	z-index: 100;
	justify-content: center;
	align-items: center;
}

.spinner {
	width: 80px;
	height: 80px;
}

.padded-container-scroll-area {
	height: calc(100vh - 180px);
}
