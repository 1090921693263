@import "colors";

.ng-select {
	min-height: 36px;
}

.ng-select.ng-select-opened > .ng-select-container {
	background: #fff;
	border-color: #b3b3b3 #ccc #d9d9d9;
}

.ng-select.ng-select-opened > .ng-select-container:hover {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
	top: -2px;
	border-color: transparent transparent #999;
	border-width: 0 5px 5px;
}

.ng-select-single .ng-select-container .ng-value-container .ng-value {
	font-size: 14px;
}

.ng-select.ng-select-opened > .ng-select-container .ng-arrow:hover {
	border-color: transparent transparent #333;
}

.ng-select.ng-select-disabled > .ng-select-container {
	background-color: #f9f9f9;
}

.ng-select .ng-has-value .ng-placeholder {
	display: none;
}

.ng-select-container {
	background: #ffffff;
	border: 0.071rem solid #dfe0eb;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	padding: 5px 0px;
	font-size: 14px;
	color: black;
	-webkit-transition: border 0.3s ease-in;
	-o-transition: border 0.3s ease-in;
	transition: border 0.3s ease-in;
	outline: none;
	width: 100%;
}

.h48 .ng-select-container {
	padding: 13px 10px;
}

.of-visible .ng-select-container .ng-value-container .ng-value {
	overflow: visible !important;
}

.ng-select .ng-select-container {
	cursor: default;
	display: -webkit-box;
	display: flex;
	outline: 0;
	overflow: hidden;
	position: relative;
	width: 100%;
	min-height: 36px;
}
.ng-select.ng-select-container:hover {
	-webkit-box-shadow: 0 0.071rem 0 rgba(0, 0, 0, 0.06);
	box-shadow: 0 0.071rem 0 rgba(0, 0, 0, 0.06);
}

.ng-select .ng-select-container .ng-value-container {
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	padding-left: 10px;
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
	color: #999;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
	left: 0;
	padding-left: 10px;
	padding-right: 15px;
}

.ng-select.ng-select-multiple.ng-select-disabled > .ng-select-container .ng-value-container .ng-value {
	background-color: #f9f9f9;
	border: 0.071rem solid #e6e6e6;
}

.ng-select.ng-select-multiple.ng-select-disabled > .ng-select-container .ng-value-container .ng-value .ng-value-label {
	padding: 0 5px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
}
.ng-select .ng-clear-wrapper {
	cursor: pointer;
	position: relative;
	width: 17px;
	top: 5px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
	font-size: 12px;
	padding: 2px;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	margin-right: 5px;
	color: $eqa-color-base-006;
	margin-top: 4px;
	background-color: $eqa-color-base-004;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled {
	background-color: #f9f9f9;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label {
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
	display: inline-block;
	padding: 0.071rem 5px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
	display: inline-block;
	padding: 0.071rem 5px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover {
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input {
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
}

.ng-select.ng-clear-wrapper {
	color: #999;
}

.ng-select.ng-clear-wrapper:hover .ng-clear {
	color: #d0021b;
}

.ng-select.ng-spinner-zone {
	padding: 5px 5px 0 0;
}

.ng-select.ng-arrow-wrapper {
	width: 25px;
	padding-right: 5px;
}

.ng-select.ng-arrow-wrapper:hover .ng-arrow {
	border-top-color: #666;
}

.ng-select.ng-arrow-wrapper .ng-arrow {
	border-color: #999 transparent transparent;
	border-style: solid;
	border-width: 5px 5px 2.5px;
}

.ng-dropdown-panel {
	background-color: #fff;
	border: 0.071rem solid #ccc;
	-webkit-box-shadow: 0 0.071rem 0 rgba(0, 0, 0, 0.06);
	box-shadow: 0 0.071rem 0 rgba(0, 0, 0, 0.06);
	left: 0;
}

.ng-dropdown-panel.ng-select-bottom {
	top: 100%;
	border-top-color: #e6e6e6;
	margin-top: -0.071rem;
}

.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
}

.ng-dropdown-panel.ng-select-top {
	bottom: 100%;
	border-bottom-color: #e6e6e6;
	margin-bottom: -0.071rem;
}

.ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items .ng-option:first-child {
}

.ng-dropdown-panel .ng-dropdown-header {
	border-bottom: 0.071rem solid #ccc;
	padding: 5px 7px;
}

.ng-dropdown-panel .ng-dropdown-footer {
	border-top: 0.071rem solid #ccc;
	padding: 5px 7px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	padding: 0.571rem 10px;
	font-weight: 500;
	color: rgba(0, 0, 0, 0.54);
	cursor: pointer;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled {
	cursor: default;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked {
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected.ng-option-marked {
	font-weight: 600;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
	background-color: #fff;
	color: rgba(0, 0, 0, 0.87);
	padding: 0.571rem 10px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
	color: #333;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
	color: $eqa-color-base-006 !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
	padding-left: 22px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label {
	font-size: 100%;
	font-weight: 400;
	padding-right: 5px;
}

.ng-select .ng-spinner-loader {
	border-radius: 50%;
	width: 17px;
	height: 17px;
	margin-right: 5px;
	font-size: 10px;
	position: relative;
	top: 5px;
	text-indent: -9999em;
	border-top: 2px solid rgba(66, 66, 66, 0.2);
	border-right: 2px solid rgba(66, 66, 66, 0.2);
	border-bottom: 2px solid rgba(66, 66, 66, 0.2);
	border-left: 2px solid #424242;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: 0.8s linear infinite load8;
	animation: 0.8s linear infinite load8;
}
