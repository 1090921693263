@import 'colors';

.form-block__content {
	margin-top: 25px;
}

.form-block__group {
	margin-bottom: 1rem;
}

.form-block_inline_group {
	margin-bottom: 1rem;
	display: flex;
}

.form-block__wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.form-block__wrapper .form-block__group:first-child {
	margin-right: 30px;
}

.everyqa-label {
	margin-bottom: 8px;
	font-size: 14px;
	line-height: 16px;
	color: $eqa-color-base-005;
	position: relative;
	display: inline-block;
}

.everyqa-label.bold {
	font-family: Rubik-Bold, sans-serif;
}

.everyqa-label.required::before {
	content: '*';
	position: absolute;
	right: -10px;
	top: -0.071rem;
	color: $eqa-color-red-002;
}

.base-sign {
	margin-top: 20px;
	text-align: right;
	color: $eqa-color-base-005;
}

.form-block__btn-wrapper {
	text-align: right;
	width: 100%;
}

.invalid-feedback {
	display: none;
	width: 100%;
	margin-top: 0.25rem;
	font-size: 0.857rem;
	color: $eqa-color-red-002;
}
