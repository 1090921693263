.m-negative-4 {
	margin-left: -4px;
}

.m-0 {
	margin: 0 !important;
}

.p-0 {
	margin: 0 !important;
}

.mt-0,
.my-0 {
	margin-top: 0 !important;
}

.mr-0,
.mx-0 {
	margin-right: 0 !important;
}

.mb-0,
.my-0 {
	margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
	margin-left: 0 !important;
}

.p-0 {
	padding: 0 !important;
}

.pt-0,
.py-0 {
	padding-top: 0 !important;
}

.pt-30 {
	padding-top: 30px !important;
}

.pr-0,
.px-0 {
	padding-right: 0 !important;
}

.pb-0,
.py-0 {
	padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
	padding-left: 0 !important;
}

.m-auto {
	margin: auto !important;
}

.mt-auto,
.my-auto {
	margin-top: auto !important;
}

.mr-auto,
.mx-auto {
	margin-right: auto !important;
}

.mb-auto,
.my-auto {
	margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
	margin-left: auto !important;
}

$loop-begin: 1 !default;
$loop-end: 100 !default;
$interval: 1 !default;

// loop
$value: $loop-begin;

@while $value <= $loop-end {
	.m-#{$value} {
		margin: #{$value}px !important;
	}
	.mx-#{$value} {
		margin-left: #{$value}px !important;
		margin-right: #{$value}px !important;
	}
	.my-#{$value} {
		margin-top: #{$value}px !important;
		margin-bottom: #{$value}px !important;
	}
	.ml-#{$value} {
		margin-left: #{$value}px !important;
	}
	.mr-#{$value} {
		margin-right: #{$value}px !important;
	}
	.mt-#{$value} {
		margin-top: #{$value}px !important;
	}
	.mb-#{$value} {
		margin-bottom: #{$value}px !important;
	}

	.p-#{$value} {
		padding: #{$value}px !important;
	}
	.px-#{$value} {
		padding-left: #{$value}px !important;
		padding-right: #{$value}px !important;
	}
	.py-#{$value} {
		padding-top: #{$value}px !important;
		padding-bottom: #{$value}px !important;
	}
	.pl-#{$value} {
		padding-left: #{$value}px !important;
	}
	.pr-#{$value} {
		padding-right: #{$value}px !important;
	}
	.pt-#{$value} {
		padding-top: #{$value}px !important;
	}
	.pb-#{$value} {
		padding-bottom: #{$value}px !important;
	}

	$value: $value + $interval;
}

.mt-8 {
	margin-top: 8px;
}

.mt-16 {
	margin-top: 16px;
}

.mt-20 {
	margin-top: 20px;
}

.mt-24 {
	margin-top: 20px;
}

.mt-33 {
	margin-top: 33px;
}

.mt-40 {
	margin-top: 40px;
}

.ml-3 {
	margin-left: 3px;
}

.mb-1 {
	margin-bottom: 0.071rem;
}

.mb-2 {
	margin-bottom: 2px;
}

.mb-7-important {
	margin-bottom: 7px !important;
}

.mb-110 {
	margin-bottom: 110px;
}

.mr-8 {
	margin-right: 8px;
}

.mr-4 {
	margin-right: 4px;
}

.mr-16 {
	margin-right: 16px;
}

.mr-40 {
	margin-right: 40px;
}

.ml-8 {
	margin-left: 8px;
}

.mb-6 {
	margin-bottom: 6px;
}

.ml-28 {
	margin-left: 28px;
}

.pt-2 {
	padding-top: 2px;
}

.pb-8 {
	padding-bottom: 8px;
}

.pb-16 {
	padding-bottom: 8px;
}

.mt-16 {
	margin-top: 16px;
}

.mt-8 {
	margin-top: 8px;
}

.mb-24 {
	margin-bottom: 24px;
}
