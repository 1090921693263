@use "sass:math";

$custom-radio-size: 1.429rem;
$custom-radio-border-width: 0.071rem;
$custom-radio-transition-speed: 100ms;

$custom-radio-pip-color: #05a081;
$custom-radio-bg-color: #ffffff;

// States
$custom-radio-unchecked-bg: white;
$custom-radio-unchecked-border: transparentize(gray, 0.6);

$custom-radio-checked-bg: $custom-radio-bg-color;
$custom-radio-checked-border: $custom-radio-bg-color;

$custom-radio-active-inset-shadow: inset 0 0 0.143rem 0.214rem rgba(0, 0, 0, 0.1);

$custom-radio-focus-shadow: 0 0 0 0.143rem transparentize(dodgerblue, 0.5);

$custom-radio-disabled-bg: lighten(black, 91.8%);
$custom-radio-disabled-pip-color: lighten(black, 80%);

input[type='radio'] {
	cursor: pointer;
	position: relative;
	display: inline-block;
	margin-right: 0.714rem;
	box-shadow: -0.143rem 0.143rem 0.286rem rgba(164, 170, 176, 0.4);
	width: $custom-radio-size - 0.143rem;
	height: $custom-radio-size - 0.143rem;
	border-radius: 100%;
	outline: none !important;
	-webkit-appearance: none;

	// Radio
	// -----

	&::before {
		position: relative;
		top: -$custom-radio-border-width;
		left: -$custom-radio-border-width;
		display: block;
		content: '';

		background: $custom-radio-unchecked-bg;

		border-radius: 100%;

		width: $custom-radio-size;
		height: $custom-radio-size;
	}

	&:checked::before {
		background: $custom-radio-checked-bg;
		border-color: $custom-radio-checked-border;
	}

	&:disabled::before {
		cursor: not-allowed;
		background-color: $custom-radio-disabled-bg;
		border-color: transparentize($custom-radio-unchecked-border, 0.2);
	}

	// Radio Pip
	// ---

	&::after {
		position: relative;
		top: -0.714rem - 0.071rem;
		left: 9px;

		display: block;
		content: '';

		background: $custom-radio-pip-color;
		border-radius: 100%;

		width: 0;
		height: 0;
	}

	&:checked::after {
		transition: all ease-in-out $custom-radio-transition-speed 0;

		top: -1.214rem;
		left: 0.214rem;

		width: 0.857rem;
		height: 0.857rem;
	}

	&:disabled::after {
		background: $custom-radio-disabled-pip-color;
	}
}

label {
	font-size: 1rem;
	display: flex;
	align-items: center;
}

input + input {
	margin-left: 0.5rem;
}
