.modal {
	-webkit-transition: all 0.1s linear;
	-o-transition: all 0.1s linear;
	transition: all 0.1s linear;
	opacity: 0;
	pointer-events: none;
}

.modal.show {
	opacity: 1;
	pointer-events: auto;
}

.panel-left,
.panel-right {
	height: 100vh;
	overflow-y: auto;
}

.panel-center {
	height: 100vh;
	overflow-y: auto;
	display: flex;
	align-items: center;
	justify-content: center;
}

.panel-right {
	position: absolute;
	top: 0;
	right: 0;
	background: #ffffff;
	-webkit-transition: all 0.1s ease-in;
	-o-transition: all 0.1s ease-in;
	transition: all 0.1s ease-in;
	will-change: transform;
	opacity: 0;
}

.panel-right .w-60 {
	width: 50% !important;
}

.panel-left {
	width: 25%;
	position: absolute;
	left: 0;
	top: 0;
	background: #ffffff;
	pointer-events: none;
	-webkit-transition: all 0.2s ease-in;
	-o-transition: all 0.2s ease-in;
	transition: all 0.2s ease-in;
	will-change: transform;
	opacity: 0;
}

.panel-left.show,
.panel-right.show {
	opacity: 1;
}

.hidden {
	display: none;
}

.modal-open {
	overflow: hidden;
}

.modal-open .modal {
	overflow-x: hidden;
	overflow-y: auto;
}

.input-group-lg {
	position: relative;
}

.input-group-lg .btn-primary {
	height: 30px;
	outline: none;
	border: none;
	background: white;
	position: absolute;
	right: 10px;
	top: 50%;
	margin-top: -15px;
}

.modal {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 105000000;
	width: 100%;
	height: 100%;
	overflow: hidden;
	outline: 0;
	background: RGBA(0, 0, 0, 0.3);
}

.modal-dialog {
	position: relative;
	width: auto;
	margin: 0.5rem;
	pointer-events: none;
}

.modal.fade .modal-dialog {
	transition: -webkit-transform 0.2s ease-out;
	transition: transform 0.1s ease-out;
	transition: transform 0.1s ease-out, -webkit-transform 0.1s ease-out;
	-webkit-transform: translate(0, -50px);
	transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
	.modal.fade .modal-dialog {
		transition: none;
	}
}

.modal.show .modal-dialog {
	-webkit-transform: none;
	transform: none;
}

.modal-dialog-scrollable {
	display: -ms-flexbox;
	display: flex;
	max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
	max-height: calc(100vh - 1rem);
	overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
	-ms-flex-negative: 0;
	flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
	overflow-y: auto;
}

.modal-dialog-centered {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
	display: block;
	height: calc(100vh - 1rem);
	content: '';
}

.modal-dialog-centered.modal-dialog-scrollable {
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
	height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
	max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
	content: none;
}

.modal-content {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 100%;
	height: 100%;
	pointer-events: auto;
	background-color: #fff;
	background-clip: padding-box;
	outline: 0;
}

.modal-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1040;
	width: 100vw;
	height: 100vh;
	background-color: #000;
}

.modal-backdrop.fade {
	opacity: 0;
}

.modal-backdrop.show {
	opacity: 0.5;
}

.modal-header {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	padding: 1rem 1rem;
	text-align: center;
	border-bottom: 0.071rem solid #dee2e6;
}

.modal-header .close {
	padding: 1rem 1rem;
	margin: -1rem -1rem -1rem auto;
}

.modal-title {
	margin-bottom: 0;
	line-height: 1.5;
}

.modal-body {
	position: relative;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	padding: 1rem;
}

.modal-footer {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: flex-end;
	justify-content: flex-end;
	padding: 1rem;
	border-top: 0.071rem solid #dee2e6;
	border-bottom-right-radius: 0.3rem;
	border-bottom-left-radius: 0.3rem;
}

.modal-footer > :not(:first-child) {
	margin-left: 0.25rem;
}

.modal-footer > :not(:last-child) {
	margin-right: 0.25rem;
}

.modal-scrollbar-measure {
	position: absolute;
	top: -9999px;
	width: 50px;
	height: 50px;
	overflow: scroll;
}
