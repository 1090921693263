@import 'colors';



.eqa-font-color-green-004 {
	color: $eqa-color-green-004 !important;
}

.eqa-font-color-base-006 {
	color: $eqa-color-base-006 !important;
}

.eqa-font-color-red-002 {
	color: $eqa-color-red-002 !important;
}

.eqa-font-color-yellow-002 {
	color: $eqa-color-yellow-002 !important;
}

.eqa-font-color-base-004 {
	color: $eqa-color-base-004 !important;
}

.eqa-font-color-base-005 {
	color: $eqa-color-base-005;
}

.eqa-font-color-base-006 {
	color: $eqa-color-base-006;
}

.text-left {
	text-align: left !important;
}

.text-right {
	text-align: right !important;
}

.text-center {
	text-align: center !important;
}

.eqa-font-weight-bold {
	font-family: Rubik-Bold, sans-serif;
}

.eqa-font-s {
	font-size: 0.857rem;
	line-height: 1rem;
}

.eqa-hover-color-green-004:hover {
	color: $eqa-color-green-004 !important;
}

.eqa-hover-color-red-002:hover {
	color: $eqa-color-red-002 !important;
}

.eqa-hover-color-yellow-002:hover {
	color: $eqa-color-yellow-002 !important;
}
