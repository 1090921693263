@import '../variables/colors';

/*a:hover {
	color: $eqa-color-green-004;
	text-decoration: none;
}*/

a:not([href]):not([tabindex]) {
	text-decoration: none;
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
	text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
	outline: 0;
}

a {
	text-decoration: none;
	cursor: pointer;
	color: $eqa-color-base-006;

	span {
		vertical-align: middle;
	}

	&:hover {
		color: black;
	}
}

.muted-roowix-link {
	font-family: Rubik, sans-serif;
	color: #9fa2b4 !important;
	font-size: 14px;
	line-height: 20px;
	-webkit-text-decoration-line: none;
	text-decoration-line: none;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	cursor: pointer;
}

.muted-roowix-link:hover {
	color: #ff6252 !important;
	-webkit-text-decoration-line: none;
	text-decoration-line: none;
}

.muted-roowix-link.active {
	color: $eqa-color-green-004;
}

.roowix-link {
	font-family: Rubik, sans-serif;
	font-size: 14px;
	line-height: 14px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	cursor: pointer;
	text-decoration: none;
	color: #36373b;

	&:hover {
		color: #048b70;

		& > svg {
			fill: $eqa-color-green-005;

			path {
				stroke: $eqa-color-green-005;
			}
		}

		& > svg {
			fill: $eqa-color-green-004;

			path {
				stroke: $eqa-color-green-004;
			}
		}
	}
}

.roowix-link.active {
	color: $eqa-color-green-004;
}

.roowix-link-dark {
	font-size: 14px;
	line-height: 16px;
	color: #36373b;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-text-decoration-line: none;
	text-decoration-line: none;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	cursor: pointer;
}

.roowix-link-dark:hover {
	-webkit-text-decoration-line: none;
	text-decoration-line: none;
	color: #05a081;
}
