@import 'colors';
@import 'variables';

.custom-control-inline {
	position: relative;
	display: block;
	padding-left: 20px;
}

.control-wrapper {
	border: $eqa-border-green-004;
	width: 15px;
	height: 15px;
	background-color: transparent;
	&:hover {
		border: $eqa-border-green-004;
	}
}
.custom-control-input {
	position: absolute;
	z-index: -1;
	opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
	border-color: $eqa-color-green-004;
	background-color: $eqa-color-green-004;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
	border-color: $eqa-color-green-004;
}

.custom-control-label {
	position: relative;
	margin-bottom: 0;
	vertical-align: top;
}

.custom-control-input {
	display: none;
}

.custom-control-label::before {
	position: absolute;
	left: -1.5rem;
	display: block;
	width: 1.1rem;
	height: 1.1rem;
	pointer-events: none;
	content: '';
	background-color: $eqa-color-base-001;
	border: $eqa-border-base-003;
}

.custom-control-label::after {
	position: absolute;
	top: 2px;
	left: -22px;
	display: block;
	width: 14px;
	height: 14px;
	content: '';
	background: no-repeat 100%/100% 100%;
}

.custom-checkbox .custom-control-label::before {
	border-radius: 0.071rem;
}

.custom-control-input:hover:not(:checked) ~ .custom-control-label::before {
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
	background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 3L5 7L11 1' stroke='%23FDFDFD' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
	border-color: $eqa-color-base-005;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
	background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 3L5 7L11 1' stroke='%23FDFDFD' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
}
