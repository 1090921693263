.w-5 {
	width: 5% !important;
}

.w-10 {
	width: 10% !important;
}

.w-20 {
	width: 20% !important;
}

.w-25 {
	width: 25% !important;
}

.w-45 {
	width: 45% !important;
	max-width: 45%;
}

.w-50 {
	width: 50% !important;
}

.w-60 {
	width: 60% !important;
}

.w-35 {
	width: 35% !important;
}

.w-38 {
	width: 38% !important;
}

.w-40 {
	width: 40% !important;
}

.w-75 {
	width: 75% !important;
}

.w-80 {
	width: 80% !important;
}

.w-90 {
	width: 90% !important;
}

.w-100 {
	width: 100% !important;
}

.h-100 {
	height: 100% !important;
}
