.ellipsis {
	display: inline;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.o-y {
	overflow-y: auto !important;
}

.o-x {
	overflow-x: auto !important;
}
