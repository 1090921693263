.operation-block {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.two-items {
	width: 53px;
}

.three-items {
	width: 89px;
}

.return,
.add,
.create,
.move,
.del,
.edit-button,
.change {
	& svg path {
		stroke: #36373b;
	}
}

.delete_section {
	& svg path {
		stroke: #36373b;
	}
}

.operation-link.return:hover,
.return:hover {
	svg {
		fill: #05a081;

		path {
			stroke: #05a081;
		}
	}
}

.operation-link.add:hover,
.add:hover {
	svg {
		fill: #05a081;

		path {
			stroke: #05a081;
		}
	}
}

.create:hover {
	& svg path {
		fill: #55bb00;
		stroke: #55bb00;
	}
}

.operation-link.del:hover,
.del:hover {
	& svg path {
		fill: #ff3333;
		stroke: #ff3333;
	}
}

.operation-link.move:hover,
.move:hover {
	& svg path {
		fill: #f2c94c;
		stroke: #f2c94c;
	}
}

.operation-link.change:hover,
.change:hover {
	svg {
		fill: #f2c94c;

		path {
			stroke: #f2c94c;
		}
	}
}

.operation-link.test-run .add {
	svg {
		fill: #05a081;

		path {
			stroke: #05a081;
		}
	}
}

.operation-link.test-run .change {
	svg {
		fill: #f2c94c;

		path {
			stroke: #f2c94c;
		}
	}
}

.operation-link {
	cursor: pointer;

	.icon,
	.text {
		vertical-align: middle;
	}

	.icon {
		margin-right: 5px;
	}
}

.disabled-link {
	color: #a4aab0 !important;

	&:hover {
		color: #a4aab0 !important;
	}
}
