@import 'colors';

$eqa-base-font-size: 1rem;
$eqa-base-line-height: 1.5rem;
$eqa-border-radius: 0.143rem;
$eqa-base-block-padding: 1.429rem;
$eqa-border-size: 0.071rem;
$eqa-border-base-003: $eqa-border-size solid $eqa-color-base-003;
$eqa-border-green-004: $eqa-border-size solid $eqa-color-green-004;
$eqa-border-yellow-002: $eqa-border-size solid $eqa-color-yellow-002;
$eqa-border-red-002: $eqa-border-size solid $eqa-color-red-002;
$eqa-btn-group-margin: 0.571rem;
$eqa-height-m: 2.75rem;

$eqa-top-space: 2.857rem;
$eqa-sidenav-width: 15.714rem;
$eqa-sidenav-width-collapsed: 3.429;
$eqa-box-shadow: 0 0.143rem 0.429rem rgb(164 170 176 / 20%);
$eqa-topnav-height: 2.857rem;

$eqa-control-size-xs: 1rem;
$eqa-control-size-s: 1.571rem;
$eqa-control-size-m: 2.143rem;
$eqa-control-size-l: 2.714rem;

$eqa-xs: 1024px;
$eqa-sm: 1280px;
$eqa-md: 1440px;
$eqa-lg: 1680px;
