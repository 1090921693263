@import '../variables/colors';

input.base-input::-webkit-input-placeholder {
	color: rgba(77, 89, 102, 0.5);
}

input.base-input::-moz-placeholder {
	color: rgba(77, 89, 102, 0.5);
}

input.base-input::-moz-placeholder {
	color: rgba(77, 89, 102, 0.5);
}

input.base-input::-ms-input-placeholder {
	color: rgba(77, 89, 102, 0.5);
}

textarea.base-input::-webkit-input-placeholder {
	color: rgba(77, 89, 102, 0.5);
}

textarea.base-input::-moz-placeholder {
	color: rgba(77, 89, 102, 0.5);
}

textarea.base-input::-moz-placeholder {
	color: rgba(77, 89, 102, 0.5);
}

textarea.base-input::-ms-input-placeholder {
	color: rgba(77, 89, 102, 0.5);
}

.base-input {
	background: #ffffff;
	border: 0.071rem solid #dfe0eb;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	padding-left: 10px;
	height: 36px;
	min-height: 36px;
	line-height: 20px;
	font-size: 14px;
	-webkit-transition: border 0.3s ease-in;
	-o-transition: border 0.3s ease-in;
	transition: border 0.3s ease-in;
	outline: none;
	width: 100%;
}

textarea.base-input {
	padding-top: 8px
}

.base-input.textarea {
	min-height: 90px;
	padding: 8px;
}

.base-input:hover,
.base-input:focus {
	border: 0.071rem solid $eqa-color-green-004;
}

.base-input.ng-dirty.ng-invalid:not(form) {
	border: 0.071rem solid $eqa-color-red-002;
}

.select-base-input {
	position: relative;
}

.select-base-input::before,
.select-base-input::after {
	content: '';
	position: absolute;
	top: 50%;
	right: 5px;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);
	background: white;
	width: 10px;
	height: 10px;
	pointer-events: none;
}

.select-base-input::after {
	right: 22px;
	z-index: 5;
	background-image: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 0L6.25 7L0.5 0H12Z' fill='%2336373B'/%3E%3C/svg%3E%0A");
	width: 12px;
	height: 7px;
}

.base-input-roowix {
	width: 500px;
	padding-left: 15px;
	border-bottom: 0.071rem solid $eqa-color-green-004;
}

.base-input-roowix {
	border: 0.071rem solid transparent;
}

.base-input-roowix:focus {
	outline: none;
	border-bottom: 0.071rem solid $eqa-color-green-004;
}

.invalid-input-cs:focus {
	border-bottom: 0.071rem solid red;
}

.search {
	position: relative;
	height: 30px;
	width: 300px;

	input {
		height: 30px;
		line-height: 30px;
		width: 268px;
		padding-left: 4px;
		border: 0.071rem solid #f2f2f2;
		border-bottom-left-radius: 2px;
		border-top-left-radius: 2px;

		&::placeholder {
			color: #a4aab0;
		}
	}

	div {
		height: 30px;
		width: 30px;
		padding: 3px 6px;
		position: absolute;
		border-top: 0.071rem solid #f2f2f2;
		border-right: 0.071rem solid #f2f2f2;
		border-bottom: 0.071rem solid #f2f2f2;
		border-bottom-right-radius: 2px;
		border-top-right-radius: 2px;
		top: 0;
		right: 0;
	}
}
