.flexible-container {
	display: flex;
	justify-content: space-between;
	align-self: flex-start;
	position: relative;
}

.flexible-content {
	flex-grow: 1;
	flex-shrink: 1;
}

.content-wrapper {
	margin-top: 60px;
	flex-grow: 1;
	flex-shrink: 1;
	padding: 20px;
}
